const MyRouter = {
  basename: '/website',
  Index: {
    path: '/'
  },
  Login: {
    path: '/login'
  },
  Register: {
    path: '/register'
  },
  Home: {
    path: '/home'
  },
  Self: {
    path: '/self'
  },
  ShopStreet: {
    path: '/shop-street'
  },
  Shop: {
    path: '/shop-info',
    realPath: '/shop-info'
  },
  News: {
    path: '/news/:id',
    realPath: '/news'
  },
  Merchant: {
    path: '/merchant'
  },
  Introduce: {
    path: '/introduce'
  },
  Order: {
    path: '/order'
  },
  View: {
    path: '/view'
  },
  Collection: {
    path: '/collection'
  },
  //   Service: {
  //     path: '/service'
  //   },
  Navigation: {
    path: '/navigation'
  },
  GoodsInfo: {
    path: '/goods/info/:pddShop/:id',
    realPath: '/goods/info'
  },
  Search: {
    path: '/search'
  },
  SecKill: {
    path: '/sec-kill'
  },
  User: {
    path: '/user'
  },
  Cart: {
    path: '/cart'
  },
  Help: {
    path: '/help'
  }
};

// for (let key in Router) {
//   Router[key].path = '/website' + Router[key].path;

//   if (Router[key].realPath) {
//     Router[key].realPath = '/website' + Router[key].realPath;
//   }
// }

export default MyRouter;
