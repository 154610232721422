// 这个必须在最前面
import {isProd} from "src/utils/envUtil";

export {default as http} from "http/http";
export {Header, ContentType} from "http/http";
export {default as qs} from "qs";
export {default as GetFormData} from "utils/GetFormData";
export {default as StatusCode} from "enum/StatusCode";

export const Method = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete"
};

export const Global = {};
Global.online = isProd;

if (Global.online) {
    Global.BASE_URL = window.BASE_URL;
    Global.BASE_SOCKET = window.BASE_SOCKET;
} else {
    Global.BASE_URL = "http://localhost:8090";
    Global.BASE_SOCKET = "ws://localhost:8090";
}
